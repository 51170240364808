import styles from 'components/SortingIndicator/SortingIndicator.modules.scss';
import React from 'react';

import Arrow from '@wienenergiegithub/ui-next/src/common/assets/icons/dropdown-arrow.inline.svg';
import classNames from 'classnames/index';

const SortingIndicator = ({ isSortedDesc, isSorted, className }) => {
  return (
    <div className={classNames(styles.iconContainer, className)}>
      <Arrow
        className={classNames(styles.arrowUp, {
          [styles.active]: !isSortedDesc && isSorted,
        })}
      />
      <Arrow
        className={classNames({
          [styles.active]: isSortedDesc && isSorted,
        })}
      />
    </div>
  );
};

export default SortingIndicator;
