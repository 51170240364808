import styles from './wochenplanung.module.scss';
import SortingIndicator from 'components/SortingIndicator/SortingIndicator';
import ScheduleSelector, {
  useSchedule,
  getCurrentWeekDate,
} from 'components/ScheduleSelector/ScheduleSelector';
import Layout from 'components/layout';
import Wrapper from 'components/Wrapper/Wrapper';
import StickyBar from 'components/StickyBar/StickyBar';

import ma48Logo from 'assets/logo/ma48_logo.png';
import api from 'api';
import useAuthStore from 'store/useAuth';
import classNames from 'classnames';
import React, {
  useLayoutEffect,
  useEffect,
  useRef,
  useState,
  useMemo,
  Fragment,
  useCallback,
} from 'react';

// import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
// import Alert from '@wienenergiegithub/ui-next/src/common/components/Alert/Alert';
import Modal from '@wienenergiegithub/ui-next/src/common/components/Modal/Modal';
import Card from '@wienenergiegithub/ui-next/src/common/components/Card/Card';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';
import Textarea from '@wienenergiegithub/ui-next/src/common/components/Textarea/Textarea';
import Heading from '@wienenergiegithub/ui-next/src/common/components/Heading/Heading';
import { useNotification } from '@wienenergiegithub/layout/src/common/modules/Notification/Context';

import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form';

import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { useDialogState, DialogDisclosure } from 'reakit/Dialog';
import Content from '@wienenergiegithub/ui-next/src/common/components/Content/Content';
import InputField from '@wienenergiegithub/ui-next/src/common/components/InputField/InputField';
import Link from '@wienenergiegithub/ui-next/src/common/components/Link/Link';

const weekDays = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

const Facility = ({ possibleDays, form, customer, article, facilityIndex }) => {
  return useMemo(
    () => (
      <div className={styles.facility}>
        {weekDays.map((day, index) => {
          const dayIsDisabled =
            !possibleDays || !possibleDays.includes(`${index}`);
          const hasOldVersion =
            form.values?.orders[`customer-${customer.id}`]?.[
              `article-${article.id}`
            ]?.[`facility-${facilityIndex}`]?.[`day-${index}-old`];
          const placeholder = dayIsDisabled ? '' : '0';

          return (
            <div
              key={`${customer.id}-${facilityIndex}-${day}`}
              className={classNames(styles.inputWrapper, {
                [styles.disabledCell]: dayIsDisabled,
                [styles.hasOldVersion]: hasOldVersion,
              })}
            >
              <InputField
                disabled={dayIsDisabled}
                noMessage
                labelHidden
                label=""
                name={[
                  'orders',
                  `customer-${customer.id}`,
                  `article-${article.id}`,
                  `facility-${facilityIndex}`,
                  `day-${index}`,
                ]}
                type="number"
                placeholder={placeholder}
                className={styles.inputField}
                {...form}
              />
            </div>
          );
        })}
      </div>
    ),
    [
      form?.values?.orders?.[`customer-${customer.id}`]?.[
        `article-${article.id}`
      ]?.[`facility-${facilityIndex}`],
    ],
  );
};

// if nested object exists
function getNested(object, ...args) {
  return args.reduce((isValid, level) => isValid && isValid[level], object);
}

const facilityRowSum = (
  form,
  facilityId,
  articleId,
  customerId,
  rowId,
  wasteCategory,
) => {
  if (
    getNested(
      form?.values.orders,
      `customer-${customerId}`,
      `article-${articleId}`,
      `facility-${facilityId}`,
    )
  ) {
    const erg = Object.entries(
      form?.values?.orders[`customer-${customerId}`][`article-${articleId}`][
        `facility-${facilityId}`
      ],
    ).reduce((total, [key, value]) => {
      if (!key.endsWith('old')) {
        return total + Number(value);
      }
      return total;
    }, 0);

    // save type of waste and amount for further calculations
    form.update(`facility-${facilityId}-row-${rowId}`, {
      value: Number(erg),
      category: wasteCategory,
    });
    return erg;
  }
  return 0;
};

const daySum = (form, facilityId, dayOfWeek) => {
  let sum = 0;
  Object.values(form?.values?.orders).forEach(customer =>
    Object.values(customer).forEach(article => {
      if (getNested(article, `facility-${facilityId}`, `day-${dayOfWeek}`)) {
        sum += Number(article[`facility-${facilityId}`][`day-${dayOfWeek}`]);
      }
    }),
  );
  // const sum = form?.values?.
  return sum;
};

const totalFacilitySum = (form, facilityId) => {
  let sum = 0;
  const sumPerCategory = { A: 0, B: 0, C: 0, D: 0, E: 0, F: 0 };
  const returnAggregations = {};
  Object.keys(form?.values).forEach(rowInfo => {
    if (rowInfo.includes(`facility-${facilityId}`)) {
      const rowCategory = form?.values[rowInfo]?.category;
      sum += form?.values[rowInfo]?.value;
      // sum up weight per category
      sumPerCategory[rowCategory.internal_name] += form?.values[rowInfo]?.value;
    }
  });
  // Divide aggregations by total amount per facility
  returnAggregations.aggr4 = ((sumPerCategory.B / sum || 0) * 100).toFixed(2);

  returnAggregations.aggr5 = (
    (sumPerCategory.B /
      (sumPerCategory.B +
        sumPerCategory.C +
        sumPerCategory.D +
        sumPerCategory.E) || 0) * 100
  ).toFixed(2);

  returnAggregations.aggr6 = (
    ((sumPerCategory.C + sumPerCategory.D + sumPerCategory.E) / sum || 0) * 100
  ).toFixed(2);

  returnAggregations.aggr7 = ((sumPerCategory.A / sum || 0) * 100).toFixed(2);

  returnAggregations.sum = sum;

  return returnAggregations;
};

const totalRowSum = (form, customerId, articleId) => {
  if (
    getNested(
      form?.values.orders,
      `customer-${customerId}`,
      `article-${articleId}`,
    )
  ) {
    let sum = 0;
    Object.values(
      form?.values?.orders[`customer-${customerId}`][`article-${articleId}`],
    ).forEach(facility =>
      Object.entries(facility).forEach(([key, value]) => {
        if (!key.endsWith('old')) {
          sum += Number(value);
        }
      }),
    );
    return sum;
  }
  return 0;
};

const rowDaySum = (form, customerId, articleId, dayOfWeek) => {
  if (
    getNested(
      form?.values.orders,
      `customer-${customerId}`,
      `article-${articleId}`,
    )
  ) {
    let sum = 0;
    Object.values(
      form?.values?.orders[`customer-${customerId}`][`article-${articleId}`],
    ).forEach(facility => {
      if (getNested(facility, `day-${dayOfWeek}`)) {
        sum += Number(facility[`day-${dayOfWeek}`]);
      }
    });
    return sum;
  }
  return 0;
};

const totalDaySum = (form, dayOfWeek) => {
  let sum = 0;
  Object.values(form?.values?.orders).forEach(customer =>
    Object.values(customer).forEach(article => {
      Object.values(article).forEach(facility => {
        if (getNested(facility, `day-${dayOfWeek}`)) {
          sum += Number(facility[`day-${dayOfWeek}`]);
        }
      });
    }),
  );
  return sum;
};

const totalSum = form => {
  let sum = 0;
  Object.values(form?.values?.orders).forEach(customer =>
    Object.values(customer).forEach(article => {
      Object.values(article).forEach(facility => {
        Object.entries(facility).forEach(([key, value]) => {
          if (!key.endsWith('old')) {
            sum += Number(value);
          }
        });
      });
    }),
  );
  return sum;
};

const Row = ({ facilities, form, rowData, index: i, wasteCategory }) => {
  return useMemo(
    () => (
      <div className={styles.row}>
        <div />
        <div className={styles.field}>
          <span>{rowData.article.name}</span>
        </div>
        <div className={styles.field}>
          <span>{rowData.customer.name}</span>
        </div>
        <div className={styles.field}>
          <span>{rowData.article?.category?.internal_name}</span>
        </div>
        <div className={styles.field}>
          <span>{rowData.article.ma48_id}</span>
        </div>

        {facilities?.map(facility => (
          // Key prop only works on implicit react Fragment
          <Fragment key={facility.id}>
            <Facility
              customer={rowData.customer}
              article={rowData.article}
              possibleDays={rowData?.facilities?.[facility.id]}
              facilityIndex={facility.id}
              form={form}
            />
            <div className={classNames(styles.field, styles.rowSum)}>
              <PlaceholderContent
                content={facilityRowSum(
                  form,
                  facility.id,
                  rowData?.article?.id,
                  rowData?.customer?.id,
                  i,
                  wasteCategory,
                )}
                placeholder="0"
              />
            </div>
          </Fragment>
        ))}
        {/* total sum of rows */}
        <>
          <div className={classNames(styles.facility, styles.field)}>
            {weekDays.map((wDay, index) => {
              return (
                <div key={wDay} className={styles.weekDayHeading}>
                  <PlaceholderContent
                    content={rowDaySum(
                      form,
                      rowData?.customer?.id,
                      rowData?.article?.id,
                      index,
                    )}
                    placeholder="0"
                  />
                </div>
              );
            })}
          </div>
          <div className={classNames(styles.field, styles.totalSum)}>
            <PlaceholderContent
              content={totalRowSum(
                form,
                rowData?.customer?.id,
                rowData?.article?.id,
              )}
              placeholder="0"
            />
          </div>
        </>
      </div>
    ),
    [
      facilities,
      rowData,
      form?.values?.orders?.[`customer-${rowData.customer.id}`]?.[
        `article-${rowData.article.id}`
      ],
      form?.values?.optimizationData?.[`customer-${rowData.customer.id}`]?.[
        `article-${rowData.article.id}`
      ],
    ],
  );
};

const DataTable = ({ customerArticles, form, facilities, group }) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.group}>
        <div className={styles.field}>{group}</div>
      </div>
      <div className={styles.table}>
        {customerArticles &&
          customerArticles.map((customer, index) => {
            return (
              <Row
                form={form}
                facilities={facilities}
                rowData={customer}
                key={`${customer.customer.id}-${customer.article.id}`}
                index={index}
                group={group}
                wasteCategory={customer.article.category}
              />
            );
          })}
      </div>
    </div>
  );
};

const PlaceholderContent = ({ content, placeholder }) => {
  const isEmpty = !content || content === '0';
  return (
    <span
      className={classNames({
        [styles.placeholderColor]: isEmpty,
        [styles.activeColor]: !isEmpty,
      })}
    >
      {!isEmpty ? content : placeholder}
    </span>
  );
};

const InfomailDialog = ({ form, facilities, handleInfoMail, dialog }) => {
  return useMemo(
    () => (
      <>
        <DialogDisclosure
          as={Button}
          variant="link"
          theme="secondary"
          {...dialog}
        >
          Infomail
        </DialogDisclosure>
        <Modal
          theme="blue"
          size="lg"
          className={styles.infomailModal}
          header="Wochenplanung Infomail"
          dialog={dialog}
        >
          <div className={styles.infomailContainer}>
            {facilities.map(facility => {
              return (
                <Card key={`infomail-${facility.id}`}>
                  <Heading size="h7" as="h2">
                    {facility.name}
                  </Heading>

                  <Textarea
                    name={[
                      'infomail',
                      `facility-${facility.id}`,
                      'general_email',
                    ]}
                    label="Allgemeiner E-mail-Text"
                    data-purpose="textarea-message"
                    {...form}
                  />
                </Card>
              );
            })}
          </div>
          <br />
          <Button onClick={handleInfoMail}>Infomail versenden</Button>
        </Modal>
      </>
    ),
    [form.values?.infomail, facilities, dialog.visible],
  );
};

const WeekSchedulePage = () => {
  const stickyRef = useRef(null);
  const [stickyBarHeight, setStickyBarHeight] = useState(0);
  const notifications = useNotification();
  const [facilities, setFacilities] = useState(null);
  // const [dispoSchedule, setDispoSchedule] = useState(null);
  const [, setDispoSchedule] = useState(null);
  // const [optimisationOngoing, setOptimisationOngoing] = useState(null);
  const schedule = useSchedule();
  const [{ selectedWeek, schedules }] = schedule;
  const userCan = useAuthStore(state => state.userCan);
  const userCanEdit = userCan('edit dispo');
  const [modifiedBy, setModifiedBy] = useState();
  const [updatedAt, setUpdatedAt] = useState();

  const [customerArticles, setCustomerArticles] = useState([]);
  const form = useFormState({
    values: {
      orders: {},
      stats: {},
      optimizationData: {},
      infomail: {},
    },
    onSubmit: async values => {
      const id = (Math.random() + 1).toString(36).substring(7);
      try {
        await api.put(`/backend/api/schedules/${selectedWeek.value}`, {
          data: values.orders,
        });
        notifications.add({
          id,
          title: 'Wochenplan gespeichert',
          icon: 'check',
          type: 'success',
          handleClose: () => {
            // eslint-disable-next-line no-console
            console.log('close');
          },
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-use-before-define
        getData();
      } catch (error) {
        notifications.add({
          title: 'Es ist ein Fehler aufgetreten',
          details: 'Bitte versuchen Sie es später erneut',
          icon: 'exclamation',
          id,
          type: 'danger',
        });
        setTimeout(() => {
          notifications.remove(id);
        }, 5000);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  });

  const getData = async () => {
    try {
      const {
        data: {
          data: respDispoSchedule,
          meta: {
            facilities: facilitiesData,
            customer_articles: respCustomerArticles,
            form_state: orderState,
          },
        },
      } = await api.get(`/backend/api/schedules/${selectedWeek.value}`);
      setCustomerArticles(respCustomerArticles);
      setDispoSchedule(respDispoSchedule);
      setFacilities(facilitiesData);
      setModifiedBy(respDispoSchedule.schedule_user?.email);

      const date = new Date(respDispoSchedule?.updated_at);
      const updateTime = date.toLocaleString('de-AT', {
        dateStyle: 'medium',
        timeStyle: 'short',
      });
      setUpdatedAt(updateTime);

      const facilitiesInfomail = facilitiesData.reduce(
        (acc, curr) => ({
          ...acc,
          [`facility-${curr.id}`]: { general_email: curr.general_email },
        }),
        {},
      );

      form.update('infomail', facilitiesInfomail);
      form.update('orders', orderState);
      if (respDispoSchedule.optimisation_quantities) {
        form.update(
          'optimizationData',
          respDispoSchedule.optimisation_quantities,
        );
      } else {
        form.update('optimizationData', {});
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    setModifiedBy(undefined);
    setUpdatedAt(undefined);
    if (!selectedWeek) {
      return;
    }
    getData();
  }, [selectedWeek]);

  // calculating top position for sticky table header
  useLayoutEffect(() => {
    const resizeListener = () => {
      setStickyBarHeight(stickyRef.current?.clientHeight);
    };

    window.addEventListener('resize', resizeListener);
    resizeListener();

    // clean up function
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const copyDialog = useDialogState();

  const currentWeek = getCurrentWeekDate();

  const getCurrentWeekId = () => {
    const currentWeekInfo = schedules?.weeks[currentWeek.year].find(
      item => item.week === currentWeek.week,
    );
    return currentWeekInfo.value;
  };

  const onCopyWeek = async () => {
    const currentWeekId = getCurrentWeekId();
    const id = (Math.random() + 1).toString(36).substring(7);
    form.update('values', {});
    try {
      await api.post(`/backend/api/schedules/copy-schedule`, {
        sourceSchedule: selectedWeek.value,
        targetSchedule: currentWeekId,
      });
      notifications.add({
        id,
        title: 'Woche erfolgreich kopiert',
        icon: 'check',
        type: 'success',
        handleClose: () => {
          // eslint-disable-next-line no-console
          console.log('close');
        },
      });
      setTimeout(() => {
        notifications.remove(id);
      }, 5000);
      // eslint-disable-next-line no-use-before-define
      getData();
    } catch (error) {
      notifications.add({
        title: 'Es ist ein Fehler aufgetreten',
        details: 'Bitte versuchen Sie es später erneut',
        icon: 'exclamation',
        id,
        type: 'danger',
      });
      setTimeout(() => {
        notifications.remove(id);
      }, 5000);
      // eslint-disable-next-line no-console
      console.error(error);
    }
    copyDialog.hide();
  };

  // const handleOptimization = async () => {
  //   const id = (Math.random() + 1).toString(36).substring(7);
  //
  //   try {
  //     setOptimisationOngoing(true);
  //     notifications.add({
  //       title: 'Optimierung gestartet',
  //       icon: 'bolt',
  //       id,
  //       type: 'info',
  //       handleClose: () => {
  //         // eslint-disable-next-line no-console
  //         console.log('close');
  //       },
  //     });
  //
  //     await api.post(
  //       `/backend/api/schedules/${selectedWeek.value}/optimization`,
  //       form.values.optimizationData,
  //     );
  //     setOptimisationOngoing(false);
  //     notifications.remove(id);
  //   } catch (e) {
  //     notifications.remove(id);
  //     setOptimisationOngoing(false);
  //
  //     notifications.add({
  //       title: 'Optimierung fehlgeschlagen',
  //       icon: 'bolt',
  //       id: 'optimisation-failed',
  //       type: 'danger',
  //     });
  //     // eslint-disable-next-line no-console
  //     console.error(e);
  //   }
  // };

  const dialog = useDialogState();

  const handleInfoMail = useCallback(async () => {
    const id = (Math.random() + 1).toString(36).substring(7);

    try {
      await api.post(
        `/backend/api/schedules/${selectedWeek.value}/infomail`,
        form?.values?.infomail,
      );
      notifications.add({
        title: 'Infomail wurde gespeichert',
        icon: 'check',
        id,
        type: 'success',
        handleClose: () => {
          // eslint-disable-next-line no-console
          console.log('close');
        },
      });
      setTimeout(() => {
        notifications.remove(id);
      }, 3000);
    } catch (error) {
      notifications.add({
        title: 'Infomail konnte nicht geschickt werden',
        details: 'Bitte versuchen Sie es später erneut',
        icon: 'exclamation',
        id,
        type: 'danger',
      });
      setTimeout(() => {
        notifications.remove(id);
      }, 5000);
      // eslint-disable-next-line no-console
      console.log(error);
    }
    dialog.hide();
  });
  const [groupSortDesc, setGroupSortDesc] = useState(false);
  const [articleSortDesc, setArticleSortDesc] = useState(false);

  const toggleGroupOrder = () => setGroupSortDesc(s => !s);
  const toggleArticleOrder = () => setArticleSortDesc(s => !s);

  let articleGroups = [];
  for (let i = 0; i < customerArticles.length; i += 1) {
    const currentGroup = customerArticles[i].customer.group;
    const groupIndex = articleGroups.findIndex(g => g[0] === currentGroup);
    if (groupIndex === -1) {
      articleGroups.push([currentGroup, [customerArticles[i]]]);
    } else {
      articleGroups[groupIndex][1].push(customerArticles[i]);
    }
  }

  for (let i = 0; i < articleGroups.length; i += 1) {
    articleGroups[i][1].sort((a, b) => {
      const nameA = a.article.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.article.name.toUpperCase(); // ignore upper and lowercase
      if (nameA > nameB === articleSortDesc) {
        return -1;
      }
      if (nameA < nameB === articleSortDesc) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  articleGroups = articleGroups.sort((a, b) => {
    const nameA = a[0].toUpperCase(); // ignore upper and lowercase
    const nameB = b[0].toUpperCase(); // ignore upper and lowercase
    if (nameA > nameB === groupSortDesc) {
      return -1;
    }
    if (nameA < nameB === groupSortDesc) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const facilityAggregations = {};

  return (
    <Wrapper>
      <Layout title="Wochenplanung">
        <Form {...form}>
          <ScrollSync>
            <div className={styles.container}>
              <StickyBar ref={stickyRef}>
                <div className={styles.actions}>
                  <div className={styles.actionContainer}>
                    {ma48Logo && (
                      <img
                        className={styles.logo}
                        src={ma48Logo}
                        alt={ma48Logo}
                      />
                    )}
                    <ScheduleSelector scheduleState={schedule} />
                    {/* {userCanEdit && (
                      <Button
                        disabled={optimisationOngoing}
                        theme="primary"
                        onClick={handleOptimization}
                      >
                        Optimierung
                      </Button>
                    )} */}
                    {userCanEdit && currentWeek && selectedWeek && (
                      <>
                        <DialogDisclosure
                          as={Button}
                          variant="link"
                          theme="secondary"
                          {...copyDialog}
                          disabled={
                            currentWeek.week === selectedWeek.week &&
                            currentWeek.year === selectedWeek.year
                          }
                        >
                          Woche kopieren
                        </DialogDisclosure>
                        <Modal
                          theme="blue"
                          size="lg"
                          className={styles.infomailModal}
                          header="Woche kopieren"
                          dialog={copyDialog}
                        >
                          <Heading as="h2" size="h5">
                            Möchten Sie die Werte der Woche {selectedWeek.week}{' '}
                            auf die Woche {currentWeek.week} kopieren?
                          </Heading>
                          <Content>
                            Achtung! Aktuelle Werte der Woche {currentWeek.week}{' '}
                            werden überschrieben.
                          </Content>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Button onClick={onCopyWeek}>Kopieren</Button>
                          </div>
                        </Modal>
                      </>
                    )}
                    {userCanEdit && (
                      <FormSubmitButton as={Button} variant="link" {...form}>
                        Speichern
                      </FormSubmitButton>
                    )}
                    {userCanEdit && facilities && (
                      <InfomailDialog
                        facilities={facilities}
                        form={form}
                        handleInfoMail={handleInfoMail}
                        dialog={dialog}
                      />
                    )}
                  </div>
                  {updatedAt && (
                    <div className={styles.versionContainer}>
                      {`Version ${updatedAt}`}
                      {modifiedBy && (
                        <Link
                          aria-label="send-email"
                          href={`mailto:${modifiedBy}`}
                          className={styles.link}
                        >
                          {modifiedBy}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </StickyBar>
              {/* {dispoSchedule && !dispoSchedule.has_avp && (
                <Container>
                  <Alert
                    title="Wochen AVP nicht konfiguriert"
                    details="Die AVP konfiguration für diese Woche ist nicht konfiguiert. Eine Optimierung ohne Konfiguration ist deshalb nicht möglich"
                    fontAwesomeIcon="siren-on"
                    type="warning"
                  />
                </Container>
              )} */}
              {stickyBarHeight && customerArticles && facilities && (
                <div className={styles.tableWrapper}>
                  <ScrollSyncPane>
                    <div
                      className={classNames(styles.headerRow, styles.fixedRow)}
                      style={{ top: stickyBarHeight }}
                    >
                      <div
                        className={classNames(styles.field, styles.headerField)}
                        role="button"
                        onClick={toggleGroupOrder}
                        onKeyDown={toggleGroupOrder}
                        tabIndex={0}
                      >
                        <span className={styles.tableHeading}>Gruppe</span>
                        <SortingIndicator
                          isSortedDesc={groupSortDesc}
                          isSorted
                          className={styles.indicator}
                        />
                      </div>
                      <div
                        className={classNames(styles.field, styles.headerField)}
                        role="button"
                        onClick={toggleArticleOrder}
                        onKeyDown={toggleArticleOrder}
                        tabIndex={0}
                      >
                        <span className={styles.tableHeading}>Fraktion</span>
                        <SortingIndicator
                          isSortedDesc={articleSortDesc}
                          isSorted
                          className={styles.indicator}
                        />
                      </div>
                      <div
                        className={classNames(styles.field, styles.headerField)}
                      >
                        <span className={styles.tableHeading}>Herkunft</span>
                      </div>
                      <div
                        className={classNames(styles.field, styles.headerField)}
                      >
                        <span className={styles.tableHeading}>Art</span>
                      </div>
                      <div
                        className={classNames(styles.field, styles.headerField)}
                      >
                        <span className={styles.tableHeading}>Art. Nr.</span>
                      </div>
                      {facilities &&
                        facilities.map(facility => (
                          <Fragment key={`facility-${facility.name}`}>
                            <div className={styles.facilityWrapper}>
                              <Content
                                className={classNames(
                                  styles.tableHeading,
                                  styles.facilityHeading,
                                )}
                              >
                                {facility.name}
                              </Content>
                              <div
                                className={classNames(
                                  styles.weekDayContainer,
                                  styles.tableHeading,
                                )}
                              >
                                {weekDays.map(wDay => (
                                  <span
                                    className={styles.weekDayHeading}
                                    key={wDay}
                                  >
                                    {wDay}
                                  </span>
                                ))}
                              </div>
                            </div>
                            <div
                              className={classNames(
                                styles.field,
                                styles.headerField,
                              )}
                            >
                              <span className={styles.tableHeading}>Sum</span>
                            </div>
                          </Fragment>
                        ))}
                      <>
                        <div
                          className={classNames(
                            styles.facilityWrapper,
                            styles.sumHeading,
                          )}
                        >
                          <Content
                            className={classNames(
                              styles.tableHeading,
                              styles.facilityHeading,
                            )}
                          >
                            SUMME
                          </Content>
                          <div
                            className={classNames(
                              styles.weekDayContainer,
                              styles.tableHeading,
                            )}
                          >
                            {weekDays.map(wDay => (
                              <span
                                className={styles.weekDayHeading}
                                key={wDay}
                              >
                                {wDay}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div
                          className={classNames(
                            styles.field,
                            styles.sumHeading,
                            styles.headerField,
                          )}
                        >
                          <span className={styles.tableHeading}>Sum</span>
                        </div>
                      </>
                    </div>
                  </ScrollSyncPane>
                  <ScrollSyncPane>
                    <div className={styles.tableContent}>
                      {articleGroups &&
                        articleGroups.map(([group, articles]) => {
                          return (
                            <DataTable
                              customerArticles={articles}
                              facilities={facilities}
                              group={group}
                              form={form}
                            />
                          );
                        })}
                    </div>
                  </ScrollSyncPane>
                  <ScrollSyncPane>
                    <div className={styles.footerRow}>
                      <div className={classNames(styles.row, styles.fixedRow)}>
                        <div
                          className={classNames(
                            styles.footerLabel,
                            styles.field,
                          )}
                        >
                          <span className={styles.tableHeading}>Summe</span>
                        </div>
                        {facilities.map(facility => {
                          facilityAggregations[facility.id] = totalFacilitySum(
                            form,
                            facility.id,
                          );

                          return (
                            <Fragment key={facility.id}>
                              <div className={styles.facility}>
                                {weekDays.map((wDay, index) => {
                                  return (
                                    <div
                                      key={wDay}
                                      className={styles.weekDayHeading}
                                    >
                                      <PlaceholderContent
                                        content={daySum(
                                          form,
                                          facility.id,
                                          index,
                                        )}
                                        placeholder="0"
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                              {/* Sum of facility */}
                              <div className={styles.facility}>
                                <PlaceholderContent
                                  content={
                                    facilityAggregations[facility.id]?.sum
                                  }
                                  placeholder="0"
                                />
                              </div>
                            </Fragment>
                          );
                        })}
                        {/* total sum */}
                        <>
                          <div className={styles.facility}>
                            {weekDays.map((wDay, index) => {
                              return (
                                <div
                                  key={wDay}
                                  className={styles.weekDayHeading}
                                >
                                  <PlaceholderContent
                                    content={totalDaySum(form, index)}
                                    placeholder="0"
                                  />
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className={classNames(
                              styles.field,
                              styles.totalSum,
                            )}
                          >
                            <PlaceholderContent
                              content={totalSum(form)}
                              placeholder="0"
                            />
                          </div>
                        </>
                      </div>
                      <div className={styles.secondFooterRow}>
                        <div
                          className={classNames(
                            styles.footerLabel,
                            styles.field,
                          )}
                        />
                        {facilities.map(facility => (
                          <div
                            className={classNames(
                              styles.aggregations,
                              styles[`facility${facility.id}`],
                            )}
                            key={facility.id}
                          >
                            <div className={styles.aggregationContainer}>
                              SY
                              <strong>
                                {facilityAggregations[facility.id].aggr4}%
                              </strong>
                            </div>
                            <div className={styles.aggregationContainer}>
                              SYHK
                              <strong>
                                {facilityAggregations[facility.id].aggr5}%
                              </strong>
                            </div>

                            <div className={styles.aggregationContainer}>
                              HW12
                              <strong>
                                {facilityAggregations[facility.id].aggr6}%
                              </strong>
                            </div>
                            <div className={styles.aggregationContainer}>
                              NK
                              <strong>
                                {facilityAggregations[facility.id].aggr7}%
                              </strong>
                            </div>
                          </div>
                        ))}
                        <div
                          className={classNames(
                            styles.aggregations,
                            styles.field,
                          )}
                        />
                      </div>
                    </div>
                  </ScrollSyncPane>
                </div>
              )}
            </div>
          </ScrollSync>
        </Form>
      </Layout>
    </Wrapper>
  );
};

export default WeekSchedulePage;
