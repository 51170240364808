import styles from 'components/ScheduleSelector/ScheduleSelector.modules.scss';
import api from 'api';
import React, { useReducer, useEffect } from 'react';
import Dropdown from '@wienenergiegithub/ui-next/src/common/components/Dropdown/Dropdown';

const transformSchedule = input => {
  const weeks = input.reduce((prev, current) => {
    const acc = { ...prev };
    if (!acc[current.year]) {
      acc[current.year] = [];
    }
    acc[current.year] = [
      ...acc[current.year],
      {
        ...current,
        label: `Woche ${current.week}`,
        value: current.id.toString(),
      },
    ];

    return acc;
  }, {});
  return {
    weeks,
    years: Object.keys(weeks).map(itm => ({ label: itm, value: itm })),
  };
};

const getCurrentWeekDate = () => {
  const now = new Date();
  const onejan = new Date(now.getFullYear(), 0, 1);
  const week = Math.ceil(
    ((now.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7,
  );
  return {
    week,
    year: now.getFullYear(),
  };
};

function scheduleReducer(state, action) {
  switch (action.type) {
    case 'week':
      return {
        ...state,
        selectedWeek: action.payload,
      };
    case 'year':
      return {
        ...state,
        selectedYear: action.payload,
      };
    case 'schedules':
      return {
        ...state,
        schedules: action.payload,
      };
    default:
      return state;
  }
}
const useSchedule = () => {
  const [data, dispatch] = useReducer(scheduleReducer, {});
  useEffect(() => {
    const getSchedule = async () => {
      try {
        const {
          data: { data: scheduleData },
        } = await api.get(`/backend/api/schedules/`);
        const transformedSchedule = transformSchedule(scheduleData);

        const currentWeekDate = getCurrentWeekDate();
        dispatch({
          type: 'year',
          payload: transformedSchedule.years.find(
            itm => itm.value === `${currentWeekDate.year}`,
          ),
        });
        dispatch({
          type: 'week',
          payload: transformedSchedule.weeks[currentWeekDate.year].find(
            itm => itm.week === currentWeekDate.week,
          ),
        });
        dispatch({
          type: 'schedules',
          payload: transformedSchedule,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };
    if (!data.schedules) {
      getSchedule();
    }
  }, [data.schedules]);

  return [data, dispatch];
};
const ScheduleSelector = ({
  scheduleState: [{ schedules, selectedWeek, selectedYear }, dispatch],
}) => {
  const handleDateSelected = type => payload => {
    dispatch({ type, payload });
  };

  return (
    <div className={styles.dropdownContainer}>
      {schedules?.weeks && (
        <Dropdown
          hasOutline
          onSelect={handleDateSelected('week')}
          selectedItem={selectedWeek}
          items={schedules?.weeks?.[selectedYear.value]}
          className={styles.dropdown}
          name="week"
          label="KW"
        />
      )}
      {schedules?.years && (
        <Dropdown
          hasOutline
          onSelect={handleDateSelected('year')}
          selectedItem={selectedYear}
          items={schedules?.years}
          className={styles.dropdown}
          name="year"
          label="Jahr"
        />
      )}
    </div>
  );
};

export { useSchedule, getCurrentWeekDate, ScheduleSelector as default };
